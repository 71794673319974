/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { useDropzone } from "react-dropzone";

import Button from "./Button";
import Icon from "./Icon";
import usePrevious from "../hooks/usePrevious";
import useDidUpdate from "../hooks/useDidUpdate";

const MAX_FILE_SIZE = 1048576;
const Upload = ({
  id,
  type,
  placeholder,
  onChange,
  variant,
  size,
  icon,
  closeOnly,
  multiple,
  onError,
  files: filesFromProp,
}) => {
  const [files, setFiles] = useState(filesFromProp || []);
  const [errors, setErrors] = useState("");

  const prevFiles = usePrevious(files);

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    multiple,
    accept: type,
    maxSize: MAX_FILE_SIZE,
    onDrop: (incomingFiles, fileRejections) => {
      const modifiedFile = incomingFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setErrors("");
      setFiles(modifiedFile);
      onChange(modifiedFile);

      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (file.size > MAX_FILE_SIZE || err.code === "file-too-large") {
            setErrors("Size is greater than 1Mb");
          }
          if (err.code === "file-invalid-type") {
            setErrors(`Error:${err.message}`);
          }
        });
      });
    },
  });

  const onRemoveFile = (file) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    onChange(acceptedFiles);
  };

  useDidUpdate(() => {
    if (onChange && !_.isEqual(prevFiles, files)) {
      onChange(files);
    }
  }, [files]);

  useEffect(() => {
    if (onError) {
      onError(errors);
    }
  }, [errors]);

  useDidUpdate(() => {
    if (prevFiles !== filesFromProp && filesFromProp?.length === 0) {
      setFiles([]);
    }
  }, [filesFromProp]);

  return (
    <Wrapper data-testid="container" className="upload-input">
      <div
        data-testid="dropzone"
        {...getRootProps({
          className: `dropzone ${variant === "dropzone" ? "" : "hidden"}`,
          id,
        })}
      >
        <input data-testid="drop-input" {...getInputProps()} />
        {files.length ? (
          files.map((file, index) => {
            return (
              <div key={index}>
                {["image/jpeg", "image/png", "image/gif"].includes(file.type) && (
                  <img src={file.preview} alt="Preview" />
                )}
                <div className="filename">
                  <i className="tg-ic-file" /> {file.name}
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <div className="">
              {placeholder || (
                <i
                  style={{
                    color: "#000",
                    fontSize: "20px",
                  }}
                  className="tg-ic-file-upload-outline"
                />
              )}
            </div>
            <Instructions>
              <span>
                Drag and drop or <b>Click to upload</b>
              </span>
              <span>{type !== "image/*" ? "PDF, DOC, " : ""}PNG, JPG accepted. Max 1MB</span>
              {errors && <span style={{ color: "#da3451" }}>{errors}</span>}
            </Instructions>
          </div>
        )}
      </div>

      {closeOnly && (
        <button type="button" className="btn" onClick={() => onRemoveFile(acceptedFiles[0])}>
          <i style={{ fontSize: "12px" }} className="tg-ic-close" />
        </button>
      )}

      {["button", "icon"].includes(variant) && !acceptedFiles.length && (
        <Button
          size={variant === "button" ? size || "md" : null}
          variant={variant === "icon" ? "icon" : "primary"}
          onClick={open}
        >
          <Icon
            name={icon || (variant === "icon" ? "add" : "upload")}
            size={variant === "icon" ? size || "md" : null}
          />
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &.upload-id-doc {
    .dropzone {
      .placeholder {
        color: #151a30;
        min-height: 130px;

        [class*="tg-ic"] {
          font-size: 130px;
        }
      }

      img {
        height: 130px;
      }
    }
  }

  .dropzone {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    min-height: 130px;
    border: 2px dashed #8f9bb3;
    padding: 20px;
    margin: auto;
    border-radius: 8px;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    &:hover {
      border-color: get-color("blue");
    }

    > * {
      width: 100%;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 300px;
    }

    .filename {
      font-weight: $font-weight-normal;
      margin: 5px auto;
    }
  }
`;

const Instructions = styled.div`
  span {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8f9bb3;

    b {
      color: #151a30;
    }

    &:last-of-type {
      font-weight: normal;
      font-size: 14px;
    }
  }
`;

Upload.defaultProps = {
  closeOnly: false,
  multiple: false,
  variant: "dropzone",
  type: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
};

Upload.propTypes = {
  closeOnly: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.elementType]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  multiple: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Upload;
