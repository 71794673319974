/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import styled from "styled-components";
import { useDispatch } from "react-redux";

/* -------------------------- Internal Dependencies ------------------------- */
import InputGroup from "../InputGroup";
import Icon from "../Icon";
import IconButton from "../IconButton";
import { listInvoices } from "../../redux/actions/InvoiceActions";
import { fetchProjects } from "../../redux/actions/ProjectActions";
import Results from "./Results";
import { isDev } from "../../utils/auth";

const SearchBox = memo(({ navHeight }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const debouncedRef = useRef();

  const sendQuery = (query) => {
    dispatch(listInvoices({ search: query, page_size: 3 }, true));
    dispatch(
      fetchProjects({ search: query, page_size: 3, ...(isDev() ? {} : { show_all: true }) }, true),
    );
  };

  const delayedQuery = useCallback(
    debounce((q) => sendQuery(q), 500),
    [],
  );

  debouncedRef.current = delayedQuery;

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedRef.current(value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    debouncedRef.current.cancel();
    // Clear the search results by calling sendQuery with empty string
    sendQuery("");
  };

  useEffect(() => {
    return () => {
      debouncedRef.current.cancel();
    };
  }, []);

  return (
    <>
      <StyledSearchInput
        data-testid="input-search"
        name="search"
        value={searchTerm}
        autoComplete="off"
        onChange={handleChange}
        className="input-search"
        placeholder="Search...."
        prepend={<Icon name="search1" />}
        isAppendText={false}
        append={<IconButton className="search-close-btn bsd-search-ic" name="x-circle" />}
        appendFunc={clearSearch}
      />

      <Results
        {...{
          searchTerm,
          clearSearch,
          navHeight,
        }}
      />
    </>
  );
});

const StyledSearchInput = styled(InputGroup)`
  box-shadow: none;
  border: 1px solid rgb(237, 241, 247);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 15px;
  // padding: 0 18px;
  background: #fff;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 18px;
  padding-left: 4px;

  .input-group-prepend .input-group-text {
    background-color: rgb(255, 255, 255);
    color: rgb(62, 72, 87);
    margin-left: 10px;
    padding: 0 10px;
    font-size: 16px;
    height: initial;
    border: none;
  }

  > div {
    margin: 0;

    input {
      width: 145px;
      padding: 0 10px;
      border: none;
      &:focus {
        border: none !important;
      }
    }
  }

  .search-close-btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(143, 155, 179);
    font-size: 16px;
  }
`;

SearchBox.propTypes = {
  className: PropTypes.string,
  navHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  selectionKey: PropTypes.string,
  size: PropTypes.string,
};

export default SearchBox;
