import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import LoadMore from "../LoadMore";
import { listMoreInvoices } from "../../redux/actions/InvoiceActions";

const InvoiceSection = ({ invoiceSearch, searchingInvoices, clearSearch }) => {
  const dispatch = useDispatch();

  return (
    <>
      {searchingInvoices.search ? null : invoiceSearch.data.length ? (
        <div className="section" data-testid="invoiceContainer">
          <div className="title">Payments</div>
          {invoiceSearch.data.map((invoice) => (
            <Link
              key={invoice?.id}
              to={`/projects/${invoice?.project?.id}/pay/${
                invoice?.type === "purchase" ? "payouts" : "payments"
              }`}
              className="result-item"
              onClick={clearSearch}
            >
              <b>{invoice?.project?.title}</b>: {invoice?.title}
            </Link>
          ))}

          <LoadMore
            type="text"
            hasMore={!!invoiceSearch.next || invoiceSearch.next === ""}
            isLoadingMore={searchingInvoices.fetchMore}
            onLoadMore={() => dispatch(listMoreInvoices(invoiceSearch.next, true))}
            variant="outline-primary"
            dataTestId="loadMoreInvoices"
          >
            View More
          </LoadMore>
        </div>
      ) : null}
    </>
  );
};

InvoiceSection.propTypes = {
  invoiceSearch: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    next: PropTypes.string,
  }).isRequired,
  searchingInvoices: PropTypes.shape({
    search: PropTypes.bool.isRequired,
    fetchMore: PropTypes.bool.isRequired,
  }).isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default InvoiceSection;
