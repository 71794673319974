import axios from "axios";
import { ENDPOINT_USERS, getCancelToken } from "../../utils/api";
import { openAlertModal } from "../../utils/modals";
import {
  LIST_USERS_START,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILED,
  DEACTIVATION_ACTIVATION_USER_START,
  DEACTIVATION_ACTIVATION_USER_SUCCESS,
  DEACTIVATION_ACTIVATION_USER_FAILED,
  LIST_TALENTS_START,
  LIST_TALENTS_SUCCESS,
  LIST_TALENTS_FAILED,
} from "../../configs/constants/ActionTypes";
import { failed, formatErrorResponse, success } from "../../utils/actions";
import { trackEvent } from "../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../analytics/events";

export const listUsers = (filter, selection, prev_selection) => {
  return (dispatch) => {
    dispatch({
      type: LIST_USERS_START,
      filter,
      selection,
      prev_selection,
    });

    axios
      .get(ENDPOINT_USERS, {
        params: filter,
        cancelToken: getCancelToken("listUsers"),
      })
      .then((response) => {
        dispatch(success(LIST_USERS_SUCCESS, { ...response.data, filter, selection }));
      })
      .catch((error) => {
        if (error.message === "aborted") return;

        dispatch(
          failed(
            LIST_USERS_FAILED,
            error.response
              ? error.response.data
              : {
                  message: "Could not fetch results",
                },
          ),
        );
      });
  };
};

export const listTalents = (filter, { silent = false, cancelToken } = {}) => {
  return (dispatch) => {
    if (!silent) {
      dispatch({
        type: LIST_TALENTS_START,
      });
    }

    axios
      .get(`${ENDPOINT_USERS}talent-pool/`, {
        cancelToken,
        params: {
          type: 1,
          ...filter,
        },
      })
      .then((response) => {
        dispatch(success(LIST_TALENTS_SUCCESS, response.data));
      })
      .catch((error) => {
        if (error.message === "aborted") return;

        dispatch(failed(LIST_TALENTS_FAILED, formatErrorResponse(error)));
      });
  };
};

export const deactivate_reactivate_User = (user, action, selectionKey) => {
  return (dispatch) => {
    dispatch({
      type: DEACTIVATION_ACTIVATION_USER_START,
      action,
      selectionKey,
    });
    axios
      .post(`${ENDPOINT_USERS}${user.id}/status/change/`, action)
      .then((response) => {
        dispatch(
          success(DEACTIVATION_ACTIVATION_USER_SUCCESS, { ...response.data, action, selectionKey }),

          openAlertModal(response.data.message),
        );

        const key = `${action.action}d`;
        const event = {
          reactivate: EVENTS.reactivate_user,
          deactivate: EVENTS.deactivate_user,
        };

        trackEvent(event[action.action], {
          [`${key}_user_id`]: user.id,
          [`${key}_username`]: user.display_name,
          event_category: CATEGORIES.settings,
        });
      })
      .catch((error) => {
        dispatch(
          failed(
            DEACTIVATION_ACTIVATION_USER_FAILED,
            error.response ? error.response.data : null,
            selectionKey,
          ),

          openAlertModal(error.response.data.message, true),
        );
      });
  };
};
