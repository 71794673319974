/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

/* -------------------------- Internel Dependencies ------------------------- */
import Progress from "../Progress";
import SummaryPlaceholder from "../SummaryPlaceholder/SummaryPlaceholder";
import ProjectSection from "./ProjectSection";
import InvoiceSection from "./InvoiceSection";

const Results = ({ searchTerm, clearSearch, navHeight }) => {
  const { search: invoiceSearch, isMakingRequest: searchingInvoices } = useSelector(
    ({ Invoice }) => Invoice || {},
  );
  const { search: projectSearch, isMakingRequest: searchingProjects } = useSelector(
    ({ Projects }) => Projects || {},
  );

  const isSearching = searchingInvoices?.search || searchingProjects?.search;

  const totalResults =
    Number(invoiceSearch?.data?.length || 0) + Number(projectSearch?.data?.length || 0);

  const noResults = totalResults === 0;

  return (
    <>
      {searchTerm && (
        <Wrapper navHeight={navHeight}>
          {isSearching && noResults && <Progress />}

          {!isSearching && totalResults === 0 ? (
            <SummaryPlaceholder description="No results found" />
          ) : (
            <div className="results">
              {totalResults > 0 && (
                <div className="counter-wrap">
                  <span className="counter" data-testid="counter">
                    Showing <strong>{totalResults}</strong> results for{" "}
                    <strong>&quot;{searchTerm}&quot;</strong>
                  </span>

                  {isSearching && <Progress />}
                </div>
              )}

              <ProjectSection
                projectSearch={projectSearch}
                searchingProjects={searchingProjects}
                clearSearch={clearSearch}
              />

              <InvoiceSection
                invoiceSearch={invoiceSearch}
                searchingInvoices={searchingInvoices}
                clearSearch={clearSearch}
              />
            </div>
          )}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 413px;
  top: ${(props) => props.navHeight}px;
  height: 100vh;
  right: 0;
  overflow-y: scroll;

  ${(props) => props?.theme?.mixins?.scrollbar}
  padding: 40px;
  position: absolute;
  background: #fff;
  border: 1px solid #efefef;
  margin-top: 0.07rem;

  .results {
    font-size: 16px;
    line-height: 19px;
    color: #151a30;

    .counter {
      &-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      b {
        font-weight: 500;
      }
    }
    .section {
      padding: 40px 0;
      border-bottom: 1px solid #edf1f7;

      &:last-of-type {
        border-bottom: none;
      }

      .title {
        font-weight: 500;
        margin-bottom: 16px;
      }
      a {
        display: block;
        text-decoration: none;
        margin-bottom: 16px;
        font-size: 14px;
        color: #151a30;
        b {
          font-weight: 500;
        }
      }

      .text-link {
        button {
          border: none;
          padding: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #da3451;
        }
        button:hover,
        button:focus {
          background-color: #fff;
          color: #da3451;
        }
      }

      .avatar.avatar-dash {
        width: 48px;
        height: 48px;

        &.avatar-initials {
          font-size: 16px;
        }
      }
    }
  }
`;

Results.propTypes = {
  searchTerm: PropTypes.string,
  clearSearch: PropTypes.func,
  navHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Results;
