/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Icon from "../../../components/Icon";
import { ONBOARDING_STAGE_TWO } from "../../../configs/constants/ActionTypes";
import { updateOnboardingStage } from "../../../redux/actions/ProfileActions";
import Payment from "../settings/Payment";
import { ContentSection } from "../settings/PersonalDetails";

const StepThree = () => {
  const dispatch = useDispatch();

  const { dollarAccount, payoneer, iban } = useSelector(({ Payments }) => Payments);

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (
      (dollarAccount && dollarAccount?.id) ||
      (iban && iban.length !== 0) ||
      (payoneer && payoneer.length !== 0)
    ) {
      setConnected(true);
    }
  }, [dollarAccount, iban, payoneer]);

  return (
    <Wrapper>
      <Payment borderBottom fromSetup />

      <Footer>
        <Link
          role="link"
          to="/onboard/step-two"
          onClick={() => dispatch(updateOnboardingStage(ONBOARDING_STAGE_TWO))}
        >
          Previous Step
        </Link>
        <Link to="/dashboard" role="link">
          {connected ? "Done" : "Skip, I’ll do this later"} <Icon name="arrow-right" />
        </Link>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled(ContentSection)`
  padding: 2.3rem;
  max-width: unset;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }

  .header {
    margin-top: 3rem;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #151a30;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .form-input {
    box-shadow: none !important;

    input {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      padding: 15px !important;
      margin-bottom: 32px;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  a {
    color: #151b2f;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default StepThree;
