import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import LoadMore from "../LoadMore";
import { fetchMoreProjects } from "../../redux/actions/ProjectActions";

const ProjectSection = ({ projectSearch, searchingProjects, clearSearch }) => {
  const dispatch = useDispatch();

  const archivedProjects = projectSearch?.data?.filter(({ archived }) => Boolean(archived));
  const unArchivedProjects = projectSearch?.data?.filter(({ archived }) => !archived);

  return (
    <>
      {!searchingProjects.search && projectSearch.data.length ? (
        <div className="section" data-testid="projectContainer">
          {unArchivedProjects?.length !== 0 && <div className="title">Projects</div>}
          {unArchivedProjects.map((project) => (
            <Link
              key={project?.id}
              to={`/projects/${project?.id}`}
              className="result-item"
              onClick={clearSearch}
            >
              {project?.title}
            </Link>
          ))}

          {archivedProjects?.length !== 0 && <div className="title">Archived Projects</div>}

          {archivedProjects.map((project) => (
            <Link
              key={project?.id}
              to={`/projects/${project?.id}`}
              className="result-item"
              onClick={clearSearch}
            >
              {project?.title}
            </Link>
          ))}

          <LoadMore
            type="text"
            hasMore={!!projectSearch.next || projectSearch.next === ""}
            onLoadMore={() => dispatch(fetchMoreProjects(projectSearch.next, true))}
            isLoadingMore={searchingProjects.fetchMore}
            variant="outline-primary"
            dataTestId="loadMoreProjects"
            bg={false}
          >
            View More
          </LoadMore>
        </div>
      ) : null}
    </>
  );
};

ProjectSection.propTypes = {
  projectSearch: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        archived: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    next: PropTypes.string,
  }).isRequired,
  searchingProjects: PropTypes.shape({
    search: PropTypes.bool.isRequired,
    fetchMore: PropTypes.bool.isRequired,
  }).isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default ProjectSection;
