import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Avatar from "../components/Avatar";
import { generateUserInitials } from "../utils/stringUtils";

const ProfileCard = () => {
  const user = useSelector(({ Auth }) => Auth.user);
  const { avatar_url, display_name, email } = user;

  return (
    <Container to="/settings/personal-details" className="mt-auto d-flex">
      <Avatar
        dataTestId="avatar"
        className="avatar-dash"
        image={avatar_url}
        initials={generateUserInitials(user)}
      />
      <div className="name-wrapper">
        <p className="m-0 name">{display_name}</p>
        <p className="m-0 email">{email}</p>
      </div>
    </Container>
  );
};

const Container = styled(Link)`
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.05);
  column-gap: 12px;

  .avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border: 1px solid #8f9bb3;

    &-dash {
      margin: 0;
    }
  }

  .name-wrapper {
    overflow: hidden;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
  }

  .email {
    font-size: 12px;
    line-height: 15px;
    color: #8f9bb3;
    word-wrap: break-word;
  }
`;

export default ProfileCard;
