/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-widgets/styles.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import "./assets/scss/index.scss";
import App from "./App";
import store from "./redux/store";
import theme from "./assets/theme";
import InterceptorSetup from "./components/InterceptorSetup";

export const ReactStrictMode = (
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <InterceptorSetup />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>
);

export const rootElement = document.getElementById("root");

const renderApp = createRoot(rootElement);
renderApp.render(ReactStrictMode);

export function moduleHotAccept(mod) {
  if (process.env.NODE_ENV !== "production" && mod && mod.hot) {
    mod.hot.accept("./App", renderApp);
  }
}
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API_KEY,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tunnel: "/api/bugs/",
});

console.log(`Version: TungaWorks@${process.env.REACT_APP_SENTRY_RELEASE}`);
moduleHotAccept(module);
