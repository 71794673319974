/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Row } from "reactstrap";
import { object, string } from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */ import Avatar from "../../../components/Avatar";
import Button from "../../../components/Button";
import FieldError from "../../../components/FieldError";
import Input from "../../../components/Input";
import Upload from "../../../components/Upload";
import Error from "../../../components/Error";
import { getUser, isClient } from "../../../utils/auth";
import { generateUserInitials } from "../../../utils/stringUtils";
import { ContentSection } from "../settings/PersonalDetails";
import {
  ONBOARDING_STAGE_ONE,
  ONBOARDING_STAGE_TWO,
  UPDATE_PROFILE_SUCCESS,
} from "../../../configs/constants/ActionTypes";
import Select from "../../../components/Select";
import { onboardingRouteLinks } from "../../../configs/constants/global";
import { useOnboardingContext } from "../../../context/OnboardingContext";
import { success } from "../../../utils/actions";
import { updateProfile } from "../../../redux/actions/ProfileActions";
import { StyledDateTimePicker } from "../../../utils/styles";

const phoneUtil = PhoneNumberUtil.getInstance();

const StepOne = () => {
  const { updateUserData } = useOnboardingContext();

  const user = getUser();

  const dispatch = useDispatch();
  const { onBoardingStage, errors } = useSelector(({ Profile }) => Profile);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      gender: user?.profile?.gender || "",
      date_of_birth: user?.profile?.date_of_birth || "",
      image: null,
      avatar_url: user?.image?.preview || user?.image,
      phone_number: user?.is_project_owner
        ? user?.company?.tel_number
        : user?.profile?.phone_number || "",
      id_document: user?.profile?.id_document,
      company: user?.is_project_owner ? user?.company?.name : user?.profile?.company || "",
    },
    validationSchema: object().shape({
      first_name: string().required("First name is required"),
      last_name: string().required("Last name is required"),
      gender: user?.is_developer ? string().required("Gender is required") : string().notRequired(),
      date_of_birth: user?.is_developer
        ? string().required("Date of Birth is required")
        : string().notRequired(),
      phone_number: string()
        .required("Phone number is required")
        .test("", "Invalid country calling code", (phoneNumber) => {
          try {
            phoneUtil.parse(phoneNumber);
            return true;
          } catch (err) {
            return false;
          }
        }),
    }),
    onSubmit: (formData) => {
      const reqData = {
        user: {
          id: user.id,
          first_name: formData.first_name,
          last_name: formData.last_name,
          image: formData.image,
        },
        phone_number: formData.phone_number,
        id_document: formData.id_document,
        gender: formData.gender,
        date_of_birth: formData.date_of_birth,
      };

      if (!(reqData.id_document instanceof File)) {
        delete reqData.id_document;
      }

      if (!(reqData.user.image instanceof File)) {
        delete reqData.user.image;
      }

      if (!user.is_developer) {
        delete reqData.date_of_birth;
        delete reqData.gender;
      }

      if (user.is_project_owner) {
        // Clients get a company object
        reqData.name = formData.company;
        reqData.tel_number = formData.phone_number;

        updateUserData(reqData);
        dispatch(updateProfile(user?.profile?.id, reqData, ONBOARDING_STAGE_TWO, false));
      } else {
        updateUserData(reqData);
        dispatch(success(UPDATE_PROFILE_SUCCESS, { stage: ONBOARDING_STAGE_TWO }));
      }
    },
  });

  const handlePhoneNumberChange = (e) => {
    const rawPhoneNumber = e.target.value;
    const cleanedPhoneNumber = rawPhoneNumber.replace(/\s/g, ""); // Remove spaces
    form.handleChange({
      target: {
        name: "phone_number",
        value: cleanedPhoneNumber,
      },
    });
  };

  useEffect(() => {
    if (errors?.profile) {
      form.setSubmitting(false);
    }
  }, [errors?.profile]);

  if (onBoardingStage.stage !== ONBOARDING_STAGE_ONE) {
    return <Redirect to={onboardingRouteLinks[onBoardingStage.stage]} />;
  }

  return (
    <form data-testid="onboard-step-one" onSubmit={form.handleSubmit} className="clearfix">
      <Wrapper>
        {errors && errors?.profile && (
          <Error message={errors.profile || "Sorry, an error occurred. Please try again."} />
        )}
        <div className="form__onboard">
          <div className="d-flex align-items-center">
            <div className="profile-pic">
              <Avatar
                dataTestId="avatar"
                className="avatar-dash avatar-initials"
                image={
                  form.values.image && form.values.image !== undefined
                    ? form.values.image.preview
                    : form.values.avatar_url
                }
                initials={generateUserInitials(user)}
              />

              <Upload
                closeOnly
                variant="button"
                type="image/*"
                icon="pencil2"
                onChange={(files) => form.setFieldValue("image", files[0])}
              />
            </div>
            <Row>
              <Col lg={6}>
                <FormGroup className="mb-3">
                  <label htmlFor="first_name">
                    First Name
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    placeholder="Enter first name"
                    className="form-input mb-0 py-0"
                    id="first_name"
                    dataTestId="first_name"
                    name="first_name"
                    value={form.values.first_name}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    required
                  />
                  {form.errors.first_name && form.touched.first_name && (
                    <FieldError message={form.errors.first_name} />
                  )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup className="mb-3">
                  <label htmlFor="last_name">
                    Last Name
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    className="form-input mb-0 py-0"
                    placeholder="Enter last name"
                    id="last_name"
                    name="last_name"
                    dataTestId="last_name"
                    value={form.values.last_name}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    required
                  />
                  {form.errors.last_name && form.touched.last_name && (
                    <FieldError message={form.errors.last_name} />
                  )}
                </FormGroup>
              </Col>

              {!isClient() && (
                <>
                  <Col lg={6}>
                    <FormGroup className="mb-3">
                      <label htmlFor="date_of_birth" className="control-label mb-2">
                        Date of Birth
                        <span className="label-style">*</span>
                      </label>

                      <StyledDateTimePicker
                        $calendar
                        id="date_of_birth"
                        data-testid="date_of_birth"
                        className="tg-date-field m-0"
                        placeholder="Select your date of birth"
                        format="DD MMM YYYY"
                        $time={false}
                        value={
                          form.values.date_of_birth ? new Date(form.values.date_of_birth) : null
                        }
                        onChange={(date_of_birth) => {
                          form.setFieldValue(
                            "date_of_birth",
                            moment.utc(date_of_birth).format("YYYY-MM-DD"),
                          );
                        }}
                        onBlur={() => form.setFieldTouched("date_of_birth", true)}
                        required
                      />
                      {form.errors.date_of_birth && form.touched.date_of_birth && (
                        <FieldError message={form.errors.date_of_birth} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="mb-3">
                      <label htmlFor="gender" className="control-label mb-2">
                        Gender
                        <span className="label-style">*</span>
                      </label>

                      <Select
                        id="gender"
                        name="gender"
                        className="form-input mb-0 py-0"
                        placeholder="Enter last name"
                        required
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.gender}
                      >
                        <option value="">Select --</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Select>
                      {form.errors.gender && form.touched.gender && (
                        <FieldError message={form.errors.gender} />
                      )}
                    </FormGroup>
                  </Col>
                </>
              )}

              <Col lg={12}>
                <FormGroup>
                  <label htmlFor="phone_number" className="control-label">
                    Phone Number
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    className="form-input mb-0 py-0"
                    placeholder="Enter phone number"
                    id="phone_number"
                    name="phone_number"
                    dataTestId="phone_number"
                    value={form.values.phone_number}
                    onChange={handlePhoneNumberChange}
                    onBlur={form.handleBlur}
                    required
                  />
                  {form.errors.phone_number && form.touched.phone_number && (
                    <FieldError message={form.errors.phone_number} />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </div>

          {!isClient() && (
            <>
              <div className="header">Upload ID (Passport or National ID card)*</div>

              <div className="row">
                <div className="col-md-12">
                  <Upload
                    type="image/*"
                    placeholder={
                      user?.profile?.id_document && (
                        <img
                          src={user.profile.id_document}
                          height="130px"
                          title="ID document"
                          alt="ID document"
                          data-testid="id_document"
                        />
                      )
                    }
                    onChange={(files) => form.setFieldValue("id_document", files[0])}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Wrapper>

      <div className="content-nav">
        <div>
          <Button
            className="next"
            type="submit"
            data-testid="submit"
            disabled={
              !form.isValid ||
              !form.values.first_name?.trim() ||
              !form.values.last_name?.trim() ||
              !form.values.phone_number?.trim() ||
              (!isClient() && !form.values.id_document) ||
              form.isSubmitting
            }
          >
            {form.isSubmitting ? "Loading" : "Next"}
          </Button>
        </div>
      </div>
    </form>
  );
};

const Wrapper = styled(ContentSection)`
  padding: 2.3rem;
  max-width: unset;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }

  .header {
    margin-top: 3rem;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #151a30;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .form-input {
    box-shadow: none !important;

    input {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      padding: 15px !important;
      margin-bottom: 32px;
    }
  }
`;

export default StepOne;
