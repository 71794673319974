import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import SkillsFilterInput from "./SkillFilterInput";
import IconButton from "../../../components/IconButton";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import { FilterContext } from "../../../context/FilterContext";

const selectOptions = [
  { value: "AND", name: "All" },
  { value: "OR", name: "Any" },
];

const StyledSkillsYearsGroup = withTheme(styled.div`
  border-radius: 4px;
  border: 1.5px solid #edf1f7;

  &::after,
  &::before {
    display: block;
    content: "";
    position: relative;
    background-color: #edf1f7;
    width: 2px;
    height: 14px;
    left: 30px;
    top: 100%;
  }

  &::after {
    bottom: 100%;
    transform: translateY(14px);
  }

  &::before {
    top: 100%;
    transform: translateY(-14px);
  }
  button:focus,
  a:focus {
    outline: none;
  }
  select {
    box-shadow: none;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    background: #fff;
    font-size: 11px;
    margin-left: 4px;
    margin-right: 4px;
  }
  button.delete-button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    i {
      font-size: ${(props) => props.theme.functions.pxToRem(18)};
      margin-left: 14px;
      color: ${(props) => props.theme.colors.gray};
    }

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const FilterBar = ({ onSubmit }) => {
  const { close } = useRightNav();
  const {
    filter,
    updateSearchOperation,
    addFilterGroup,
    updateGroupOperation,
    addFilterItem,
    deleteSkillGroup,
    totalSkills,
  } = useContext(FilterContext);
  const { skillYearsGroups, searchOperation } = filter;

  const onDeleteSkillGroup = (groupIndex) => {
    deleteSkillGroup(groupIndex);
  };

  const handleClearAllFilters = (e) => {
    e.preventDefault();
    onSubmit(true);
    close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
    close();
  };

  return (
    <FilterBarWrapper data-testid="filter-component">
      <div className="d-flex pb-3 justify-content-between" data-testid="filter-operation-div">
        <div>
          Match{" "}
          <select
            style={{ width: "auto" }}
            value={searchOperation}
            data-testid="search-operation"
            onChange={(ev) => updateSearchOperation(ev.target.value)}
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>{" "}
          filter groups
        </div>

        {(totalSkills > 0 || skillYearsGroups?.length > 0) && (
          <Button
            type="button"
            className="text-dark-blue mt-n3 clear-btn"
            role="link"
            onClick={handleClearAllFilters}
          >
            Clear all filters
          </Button>
        )}
      </div>

      {skillYearsGroups.map((group, groupIndex) => {
        return (
          <div key={groupIndex}>
            <StyledSkillsYearsGroup>
              <div className="p-3 pb-0">
                <div className="d-flex justify-content-between">
                  <div>
                    Match{" "}
                    <select
                      value={group.searchOperation}
                      onChange={(ev) => updateGroupOperation(groupIndex, ev.target.value)}
                      data-testid="filter-group-operation"
                    >
                      {selectOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>{" "}
                    skills in this group
                  </div>
                  <IconButton
                    name="delete-outline"
                    size="sm"
                    data-testid="delete-skill-group-btn"
                    className="p-0 delete-button"
                    onClick={() => onDeleteSkillGroup(groupIndex)}
                  />
                </div>
                {group.skills.map((val, skillIndex) => {
                  return (
                    <SkillsFilterInput
                      key={skillIndex}
                      groupIndex={groupIndex}
                      skillIndex={skillIndex}
                      skillName={val.name}
                      operation={val.operation}
                      years={val.years}
                    />
                  );
                })}
                <Button
                  type="button"
                  className="px-0 mt-2 mb-0 text-dark-blue"
                  role="link"
                  onClick={() => addFilterItem(groupIndex)}
                >
                  <Icon name="round-add" /> Add filter
                </Button>
              </div>
            </StyledSkillsYearsGroup>
            {groupIndex !== skillYearsGroups.length - 1 && (
              <div className="px-4 py-3 fw-bolder" data-testid="search-operation-div">
                {searchOperation}
              </div>
            )}
          </div>
        );
      })}
      <Button
        type="button"
        className="px-0 my-3 text-dark-blue"
        role="link"
        onClick={() => addFilterGroup()}
      >
        <Icon name="round-add" /> Add filter group
      </Button>
      <br />
      <Button
        type="submit"
        className="add-button"
        data-testid="apply-filter"
        disabled={totalSkills === 0}
        onClick={handleSubmit}
      >
        Apply filter
      </Button>
    </FilterBarWrapper>
  );
};

FilterBar.propTypes = {
  onSubmit: PropTypes.func,
};
const FilterBarWrapper = styled.div`
  .add-button {
    border-radius: 4px;
    background: #da3451;
    color: #fff;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    height: 40px;
    font-weight: 500;
  }

  .clear-btn {
    margin-top: -10px;
  }
  select {
    box-shadow: none;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    background: #fff;
    font-size: 11px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export default FilterBar;
