/* eslint-disable react/prop-types */
import React from "react";
import { FormGroup } from "reactstrap";
import styled from "styled-components";

import Input from "../Input";
import Select from "../Select";

import {
  HOURS_WORKED_LINE_ITEM,
  COMMITMENT_PAYMENT_LINE_ITEM,
  CREDIT_LINE_ITEM,
} from "../../configs/constants/invoiceConstants";

const PaymentInvoiceItem = ({
  idx,
  updatePayout,
  amount,
  description,
  lineItemType,
  currency,
  remove,
  required,
}) => {
  return (
    <Container className="d-flex flex-column gap-3">
      <div className="invoice-item">
        <div className="input-groups">
          <FormGroup className="type m-0">
            <label htmlFor="line_item_type" className="control-label">
              Type
            </label>

            <Select
              id="line_item_type"
              className="line_item_type"
              data-testid={`line_item_type_${idx}`}
              onChange={(e) => updatePayout("line_item_type", e.target.value)}
              value={lineItemType}
              required
            >
              <option value="">Select Type</option>
              <option value={HOURS_WORKED_LINE_ITEM}>{HOURS_WORKED_LINE_ITEM}</option>
              <option value={COMMITMENT_PAYMENT_LINE_ITEM}>{COMMITMENT_PAYMENT_LINE_ITEM}</option>
              <option value={CREDIT_LINE_ITEM}>{CREDIT_LINE_ITEM}</option>
            </Select>
          </FormGroup>

          <FormGroup className="description m-0">
            <label htmlFor="description">Description</label>

            <Input
              id="description"
              value={description}
              onChange={(e) => updatePayout("description", e.target.value)}
              className="py-0"
              placeholder="Enter a title for this payment"
              dataTestId={`paymentDescription${idx}`}
              required
            />
          </FormGroup>

          <FormGroup className="amount m-0">
            <label htmlFor="amount">Amount {currency}</label>

            <Input
              type="number"
              id="amount"
              value={amount}
              className="py-0"
              onChange={(e) =>
                lineItemType === CREDIT_LINE_ITEM || e.target.value >= 0
                  ? updatePayout("amount", e.target.value)
                  : null
              }
              placeholder="Enter amount"
              dataTestId={`amount${idx}`}
              required={required}
            />
          </FormGroup>
        </div>

        <button
          data-testid={`remove-${idx}`}
          type="button"
          className="btn px-0 bg-transparent"
          onClick={remove}
        >
          Remove
        </button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .invoice-item {
    width: 100%;
    padding: 15px 20px;
    background-color: #8f9bb30d;
  }

  .selected {
    padding: 0 !important;
  }

  .input-groups {
    display: flex;
    column-gap: 20px;
    margin-bottom: 10px;
  }

  .type {
    flex-basis: 136px;
  }
  .description {
    flex-basis: 340px;
  }
  .amount {
    flex-basis: 190px;
  }

  #description,
  #amount {
    background-color: #fff !important;
  }

  .btn {
    height: unset;
    line-height: unset;
    color: #da3451;
    font-weight: 500;
    font-size: 14px;
  }

  input,
  select.line_item_type {
    padding-left: 15px;
    padding-right: 15px;
  }

  select.line_item_type {
    padding-right: 30px;

    + div {
      right: 10px;
    }
  }
`;

export default PaymentInvoiceItem;
