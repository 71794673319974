/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from "reactstrap";
import { object, string } from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import Avatar from "../../../components/Avatar";
import CountrySelector from "../../../components/CountrySelector";
import Input from "../../../components/Input";
import Success from "../../../components/Success";
import Error from "../../../components/Error";
import Upload from "../../../components/Upload";
import { updateProfile } from "../../../redux/actions/ProfileActions";
import { generateUserInitials } from "../../../utils/stringUtils";
import FieldError from "../../../components/FieldError";
import { getUser } from "../../../utils/auth";
import { trackEvent } from "../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { openAlertModal } from "../../../utils/modals";
import Select from "../../../components/Select";
import { StyledDateTimePicker } from "../../../utils/styles";

const phoneUtil = PhoneNumberUtil.getInstance();

const Profile = () => {
  const { user } = useSelector(({ Auth }) => Auth);

  const { isSaved } = useSelector(({ Profile: UserProfile }) => UserProfile);

  const [avatarUploadError, setAvatarUploadError] = useState("");
  const dispatch = useDispatch();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user?.profile?.phone_number || "",
      gender: user?.profile?.gender || "",
      date_of_birth: user?.profile?.date_of_birth || "",
      street: user?.profile?.street || "",
      plot_number: user?.profile?.plot_number || "",
      city: user?.profile?.city || "",
      postal_code: user?.profile?.postal_code || "",
      country: user?.profile?.country || "",
      avatar_url: user?.avatar_url,
      id_document: user?.profile?.id_document || "",
    },
    validationSchema: object().shape({
      first_name: string().required("First name is required"),
      last_name: string().required("Last name is required"),
      gender: user?.is_developer ? string().required("Gender is required") : string().notRequired(),
      date_of_birth: user?.is_developer
        ? string().required("Date of Birth is required")
        : string().notRequired(),
      street: !user.is_project_owner
        ? string().required("Street is required")
        : string().notRequired(),
      plot_number: !user.is_project_owner
        ? string().required("Plot number is required")
        : string().notRequired(),
      postal_code: !user.is_project_owner
        ? string().required("Postal code is required")
        : string().notRequired(),
      city: !user.is_project_owner ? string().required("City is required") : string().notRequired(),
      country: !user.is_project_owner
        ? string().required("Country is required")
        : string().notRequired(),

      phone_number: string()
        .required("Phone number is required")
        .test("", "Invalid country calling code", (phoneNumber) => {
          try {
            phoneUtil.parse(phoneNumber);
            return true;
          } catch (err) {
            return false;
          }
        }),
    }),
    onSubmit: (formData) => {
      const data = { ...formData };
      const nestedUser = {};

      ["first_name", "last_name", "image"].forEach((key) => {
        if (data[key] || data[key] === "") {
          nestedUser[key] = data[key] || "";
        }
        delete data[key];
      });

      if (!(data.id_document instanceof File)) {
        delete data.id_document;
      }

      if (user.is_project_owner) {
        delete data.street;
        delete data.plot_number;
        delete data.postal_code;
        delete data.city;
        delete data.country;
      }

      if (!user.is_developer) {
        delete data.date_of_birth;
        delete data.gender;
      }

      const successCb = () => {
        if (document) {
          window.scrollTo(0, 0);
          form.setSubmitting(false);
        }

        trackEvent(EVENTS.update_personal_details, {
          user_id: user.id,
          username: user.display_name,
          event_category: CATEGORIES.settings,
        });
      };

      const feedbackCb = {
        successCb,
        failureCb: (err) => {
          form.setSubmitting(false);
          openAlertModal(err.message || "Sorry, an error occurred, please try again.", true);
        },
      };

      dispatch(
        updateProfile(
          user.profile.id,
          {
            ...data,
            user: { id: user.id, ...nestedUser },
          },
          null,
          false,
          true,
          feedbackCb,
        ),
      );
    },
  });

  const handlePhoneNumberChange = (e) => {
    const rawPhoneNumber = e.target.value;
    const cleanedPhoneNumber = rawPhoneNumber.replace(/\s/g, ""); // Remove spaces
    form.handleChange({
      target: {
        name: "phone_number",
        value: cleanedPhoneNumber,
      },
    });
  };

  return (
    <ContentSection className="position-relative">
      <form method="post" onSubmit={form.handleSubmit}>
        {isSaved.profile && <Success message="Profile saved successfully" />}
        {avatarUploadError && <Error message={avatarUploadError} />}

        <div className="header">Biodata</div>
        <div className="d-flex align-items-center">
          <div className="profile-pic">
            <Avatar
              dataTestId="avatar"
              className="avatar-dash avatar-initials"
              image={
                form.values.image && form.values.image !== undefined
                  ? form.values.image.preview
                  : form.values.avatar_url
              }
              initials={generateUserInitials(user)}
            />

            <Upload
              closeOnly
              id="upload-1"
              variant="button"
              type="image/*"
              icon="pencil2"
              onError={setAvatarUploadError}
              onChange={(files) => form.setFieldValue("image", files[0])}
            />
          </div>

          <div className="row">
            <div className="col-6">
              <FormGroup className="mb-3">
                <label htmlFor="first_name" className="control-label mb-2">
                  First Name
                  <span className="label-style">*</span>
                </label>

                <Input
                  id="first_name"
                  name="first_name"
                  className="form-input mb-0 py-0"
                  placeholder="Enter first name"
                  required
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.first_name}
                />
                {form.errors.first_name && form.touched.first_name && (
                  <FieldError message={form.errors.first_name} />
                )}
              </FormGroup>
            </div>

            <div className="col-6">
              <FormGroup className="mb-3">
                <label htmlFor="last_name" className="control-label mb-2">
                  Last Name
                  <span className="label-style">*</span>
                </label>
                <Input
                  id="last_name"
                  name="last_name"
                  className="form-input mb-0 py-0"
                  placeholder="Enter last name"
                  required
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.last_name}
                />
                {form.errors.last_name && form.touched.last_name && (
                  <FieldError message={form.errors.last_name} />
                )}
              </FormGroup>
            </div>

            {user?.is_developer && (
              <>
                <div className="col-6">
                  <FormGroup className="mb-3">
                    <label htmlFor="date_of_birth" className="control-label mb-2">
                      Date of Birth
                      <span className="label-style">*</span>
                    </label>

                    <StyledDateTimePicker
                      $calendar
                      id="date_of_birth"
                      data-testid="date_of_birth"
                      className="tg-date-field"
                      placeholder="Select your date of birth"
                      format="DD MMM YYYY"
                      $time={false}
                      value={form.values.date_of_birth ? new Date(form.values.date_of_birth) : null}
                      onChange={(date_of_birth) => {
                        form.setFieldValue(
                          "date_of_birth",
                          moment.utc(date_of_birth).format("YYYY-MM-DD"),
                        );
                      }}
                      onBlur={() => form.setFieldTouched("date_of_birth", true)}
                      required
                    />
                    {form.errors.date_of_birth && form.touched.date_of_birth && (
                      <FieldError message={form.errors.date_of_birth} />
                    )}
                  </FormGroup>
                </div>

                <div className="col-6">
                  <FormGroup className="mb-3">
                    <label htmlFor="gender" className="control-label mb-2">
                      Gender
                      <span className="label-style">*</span>
                    </label>

                    <Select
                      id="gender"
                      name="gender"
                      className="form-input mb-0 py-0"
                      placeholder="Enter last name"
                      required
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      value={form.values.gender}
                    >
                      <option value="">Select --</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Select>
                    {form.errors.gender && form.touched.gender && (
                      <FieldError message={form.errors.gender} />
                    )}
                  </FormGroup>
                </div>
              </>
            )}

            <div className="col-12">
              <FormGroup className="mb-3">
                <label htmlFor="phone_number" className="control-label mb-2">
                  Phone Number
                  <span className="label-style">*</span>
                </label>

                <Input
                  id="phone_number"
                  name="phone_number"
                  className="form-input mb-0 py-0"
                  placeholder="Enter phone number"
                  required
                  onChange={handlePhoneNumberChange}
                  onBlur={form.handleBlur}
                  value={form.values.phone_number}
                />
                {form.errors.phone_number && form.touched.phone_number && (
                  <FieldError message={form.errors.phone_number} />
                )}
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup className="mb-3">
                <label htmlFor="role" className="control-label mb-2">
                  Role
                </label>
                <Input placeholder="Role" value={getUser().display_type} disabled />
              </FormGroup>
            </div>
          </div>
        </div>

        {!user.is_project_owner && (
          <>
            <hr />

            <div className="header">Address</div>
            <div className="row">
              <div className="col-md-6">
                <FormGroup className="mb-3">
                  <label htmlFor="street" className="control-label mb-2">
                    Street
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    id="street"
                    name="street"
                    className="form-input mb-0 py-0"
                    placeholder="Enter street"
                    required
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.street}
                  />

                  {form.errors.street && form.touched.street && (
                    <FieldError message={form.errors.street} />
                  )}
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup className="mb-3">
                  <label htmlFor="plot_number" className="control-label mb-2">
                    Number / Plot
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    id="plot_number"
                    name="plot_number"
                    className="form-input mb-0 py-0"
                    placeholder="2"
                    required
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    type="number"
                    value={form.values.plot_number}
                  />
                  {form.errors.plot_number && form.touched.plot_number && (
                    <FieldError message={form.errors.plot_number} />
                  )}
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup className="mb-3">
                  <label htmlFor="postal_code" className="control-label mb-2">
                    Zip Code
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    id="postal_code"
                    name="postal_code"
                    placeholder="10001"
                    className="form-input mb-0 py-0"
                    required
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.postal_code}
                  />
                  {form.errors.postal_code && form.touched.postal_code && (
                    <FieldError message={form.errors.postal_code} />
                  )}
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup className="mb-3">
                  <label htmlFor="city" className="control-label mb-2">
                    City
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    id="city"
                    name="city"
                    className="form-input mb-0 py-0"
                    placeholder="Enter City"
                    required
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.city}
                  />
                  {form.errors.city && form.touched.city && (
                    <FieldError message={form.errors.city} />
                  )}
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup className="mb-3">
                  <label htmlFor="country" className="control-label mb-2">
                    Country
                    <span className="label-style">*</span>
                  </label>

                  <CountrySelector
                    className="mb-0"
                    id="country"
                    value={form.values.country}
                    onBlur={() => form.setFieldTouched("country", true)}
                    onChange={(country) => form.setFieldValue("country", country)}
                    required
                  />
                  {form.errors.country && form.touched.country && (
                    <FieldError message={form.errors.country} />
                  )}
                </FormGroup>
              </div>
            </div>

            <hr />

            <div className="header">Identification</div>
            <div className="row">
              <div className="col-md-12">
                <Upload
                  id="upload-2"
                  type="image/*"
                  placeholder={
                    user?.profile?.id_document && (
                      <img
                        src={user.profile.id_document}
                        height="130px"
                        title="ID document"
                        alt="ID document"
                        data-testid="id_document"
                      />
                    )
                  }
                  onChange={(files) => form.setFieldValue("id_document", files[0])}
                />
              </div>
            </div>
          </>
        )}

        <div className="col-12 save-container">
          <button
            disabled={form.isSubmitting || !form.isValid}
            type="submit"
            className="btn btn-primary float-right save"
          >
            {form.isSubmitting ? " Saving..." : " Save"}
          </button>
        </div>
      </form>
    </ContentSection>
  );
};

export const ContentSection = styled.div`
  padding-bottom: 100px !important;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
  .save-container {
    background: rgba(237, 241, 247, 0.25);
    height: fit-content;
    display: flex;
    padding: 20px 40px;
    position: absolute;
    bottom: 0;
    left: 0;

    button {
      margin: 0 0 0 auto;
    }
  }
  .file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100px;
    width: 100%;
    background: #ffffff;
    border: 1px dashed #c2ccd9;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    text-align: center;

    color: #8f9bb3;
    span {
      position: relative;
      color: #062e64;
      font-weight: 500;
      margin: 0 2px;
      input {
        position: absolute;
        width: 100%;
        opacity: 0;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: row;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1;

    /* typography */
    color: white;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #ff6e40;
    box-shadow: 0 0 13px 3px #ff6e40;
  }
  hr {
    border-top: 1px solid #edf1f7;
    margin: 2rem 0 !important;
    opacity: 1;
  }
  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #151a30;
    margin-bottom: 24px;
  }

  form,
  .form__onboard {
    .control-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #151a30;
    }

    .profile-pic {
      position: relative;
      margin-right: 40px;
      height: fit-content;
      align-self: flex-start;
      margin-top: 10px;

      button {
        border: none;
        border-radius: 50%;
        background: #fff;
        min-width: 25px;
        height: 25px;
        position: absolute;
        bottom: 0;
        right: 10px;
        box-shadow: 0px 3px 8px rgba(143, 155, 179, 0.5);
        padding: 0;

        &.btn {
          font-size: initial;
          line-height: 0px;
        }

        .action-text {
          display: none;
        }

        i {
          color: #232735;
        }
      }
    }

    input,
    select {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      color: #3e4857;
    }

    select {
      height: 40px;
    }

    .avatar {
      width: 100px;
      height: 100px;

      &.avatar-icon i {
        font-size: 100px;
      }

      &.avatar-initials {
        font-size: 24px !important;
      }
    }

    .save {
      box-shadow: none;
      border: none;
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #062e64;

      &:disabled {
        color: rgba(6, 46, 100, 0.25);
      }
    }
  }
`;

export default Profile;
